body {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    background-color: black;
    color: #FFFFFF;
}

/* ---------- Scroll ---------- */

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background-color: #FFFFFF;
}

::-webkit-scrollbar-track {
    border: 1px solid black;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: darkred;
}

/* ---------- Debug ---------- */

/*div.view {*/
/*    position: relative;*/
/*    border: 1px solid red;*/
/*    display: inline-block;*/
/*    background-color: black;*/
/*}*/

/* ---------- Card ---------- */

.card {
    position: relative;
    width: 91px;
    height: 133px;
    border: 1px solid transparent;
    border-radius: 5px;
    overflow: hidden;
    flex-shrink: 0;
}

.card .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 91px;
    height: 133px;
}

.card .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 91px;
    height: 20px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    text-align: center;
}

.card.selected {
    border: 1px solid red;
}

.card .header.protect1 {
    background-image: url("/images/field/protect_header.png");
}

.card .header.force1 {
    background-image: url("/images/field//force_header.png");
}

.card .header.accel1 {
    background-image: url("/images/field//accel_header.png");
}

.card .header span.count {
    color: black;
    font-weight: bold;
    padding: 2px;
    border: 1px solid black;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 10px;
}

.card .protect1 .count, .card-circle.protect2 .count {
    background-color: #6ba348;
}

.card .force1 .count, .card-circle.force2 .count {
    background-color: #57c3e6;
}

.card .accel1 .count, .card-circle.accel2 .count {
    background-color: #d2a641;
}

.card .grade {
    text-align: center;
    width: 15px;
    height: 15px;
    border: 1px solid black;
    background-color: gray;
    line-height: normal;
    font-size: 13px;
    color: white;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 10px;
}

.card .skill {
    position: absolute;
    top: 21px;
    left: 1px;
    width: 15px;
    height: 15px;
    filter: invert(100%);
    border-radius: 10px;
    border: 1px solid black;
    background-color: white;
    background-size: 13px;
    background-position: center;
    background-repeat: no-repeat;
}

.card .skill.triple-drive {
    background-image: url("/images/card/tridrive.png");
}

.card .skill.boost {
    background-image: url("/images/card/boost.png");
}

.card .skill.intercept {
    background-image: url("/images/card/intercept.png");
}

.card .skill.twin-drive {
    background-image: url("/images/card/twindrive.png");
}

.card .gift {
    width: 15px;
    text-align: center;
    position: absolute;
    top: 41px;
    left: 1px;
    color: white;
    font-size: 4px;
    border: 1px solid white;
    border-radius: 3px;
    text-transform: uppercase;
}

.card .gift.protect {
    background-color: #6ba348;
}

.card .gift.accel {
    background-color: #d2a641;
}

.card .gift.force {
    background-color: royalblue;
}

.card .shield {
    text-align: center;
    position: absolute;
    top: 51px;
    left: 1px;
    width: 15px;
    color: black;
    font-weight: bold;
    font-size: 10px;
    background-color: white;
    border: 1px solid black;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.card .shield:before{
    content: '';
    background: url("images/shield.svg");
    background-size: 15px;
    width: 15px;
    height: 15px;
    display: block;
}

.card .footer {
    position: absolute;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    border-top: 1px solid black;
    width: 87px;
    padding: 2px;
    left: 0;
    bottom: 0;
    height: 13px;
    background-color: #d7d1de;
    color: black;
    font-weight: bold;
}

.card .footer span.power {
    position: absolute;
    left: 2px;
    bottom: 1px;
}

.card .footer span.power:before {
    content: '';
    background-image: url("images/power.png");
    background-size: 11px;
    background-repeat: no-repeat;
    width: 13px;
    height: 10px;
    display: inline-block;
}

.card .footer span.critical {
    position: absolute;
    right: 2px;
    bottom: 1px;
}

.card .footer span.critical:before {
    content: '';
    background-image: url("images/critical.png");
    background-size: 11px;
    background-repeat: no-repeat;
    width: 13px;
    height: 10px;
    display: inline-block;
}

.card .soul {
    position: absolute;
    /*padding-left: 14px;*/
    text-align: center;
    right: 0;
    top: 21px;
    background-color: lightgray;
    color: black;
    border: 1px solid black;
    border-right: 0 solid transparent;
    padding: 2px;
    /*border-radius: inherit;*/
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    font-size: 12px;
    font-weight: bold;
}

/*.card .soul:before{*/
/*    content: '';*/
/*    background: url("/images/old_images/v2/soul.svg");*/
/*    background-size: 12px;*/
/*    width: 12px;*/
/*    height: 12px;*/
/*    display: block;*/
/*}*/

.card .gauge {
    position: absolute;
    right: 0;
    top: 51px;
    text-align: center;
    background-color: lightgray;
    color: black;
    border: 1px solid black;
    border-right: 0 solid transparent;
    padding: 1px;
    /*border-radius: inherit;*/
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    font-size: 10px;
    font-weight: bold;
}

.card .gauge:before{
    content: '';
    background: url("images/gear.svg");
    background-size: 12px;
    width: 12px;
    height: 12px;
    display: block;
}

/* ---------- Card circle ---------- */

.card-circle {
    position: relative;
    width: 135px;
    height: 135px;
    text-align: center;
    overflow: hidden;
}

.card-circle div.count {
    color: black;
    font-weight: bold;
    padding: 2px;
    position: absolute;
    left: 0;
    top: 0;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 11px;
}

.card-circle div.view {
    position: absolute;
    right: 6px;
    bottom: 2px;
}

.card-circle div.bind {
    position: absolute;
    right: 6px;
    bottom: 24px;
}

.card-circle.discard div.count, .card-circle.deck div.count {
    position: absolute;
    left: 95px;
    top: 4px;
    width: 12px;
    height: 12px;
    background-color: lightgray;
    border-radius: 10px;
    font-weight: bold;
}

.card-circle.rearguard {
    background-image: url('/images/field/rearguard.png');
}

.card-circle.vanguard {
    background-image: url('/images/field/vanguard.png');
}

.card-circle.force2 {
    background-image: url("/images/field/V-GM2-0001EN.png");
}

.card-circle.accel2 {
    background-image: url("/images/field/V-GM2-0002EN.png");
}

.card-circle.protect2 {
    background-image: url("/images/field/V-GM2-0003EN.png");
}

.card-circle.rearguard.protect2 {
    background-image: url("/images/field/V-GM2-0003EN.png"), url('/images/field/rearguard.png');
}

.card-circle.vanguard.protect2 {
    background-image: url("/images/field/V-GM2-0003EN.png"), url('/images/field/vanguard.png');
}

.card-circle.rearguard.force2 {
    background-image: url("/images/field/V-GM2-0001EN.png"), url('/images/field/rearguard.png');
}

.card-circle.vanguard.force2 {
    background-image: url("/images/field/V-GM2-0001EN.png"), url('/images/field/vanguard.png');
}

.card-circle.rearguard.accel2 {
    background-image: url("/images/field/V-GM2-0002EN.png"), url('/images/field/rearguard.png');
}

.card-circle.vanguard.accel2 {
    background-image: url("/images/field/V-GM2-0002EN.png"), url('/images/field/vanguard.png');
}

.card-circle.rearguard.force1 {
    background-image: url("/images/field/V-GM-0001EN.png"), url('/images/field/rearguard.png');
}

.card-circle.vanguard.force1 {
    background-image: url("/images/field/V-GM-0001EN.png"), url('/images/field/vanguard.png');
}

.card-circle.rearguard.accel1 {
    background-image: url("/images/field/V-GM-0002EN.png"), url('/images/field/rearguard.png');
}

.card-circle.vanguard.accel1 {
    background-image: url("/images/field/V-GM-0002EN.png"), url('/images/field/vanguard.png');
}

.card-circle.deck {
    background-image: url("/images/field/deck_bg.png");
}

.card-circle.discard {
    background-image: url("/images/field/empty_bg.png");
}

.card-circle .card {
    /*display: none;*/
    margin-left: 21px;
    /*transform: rotate(90deg);*/
}

.card-circle.rest .card {
    transform: rotate(90deg);
}

/* ---------- Guardian ---------- */

.guardian {
    margin: auto;
    width: 135px;
    height: 93px;
    /*margin-left: 21px;*/
}

.guardian .card {
    left: 21px;
    top: -21px;
    transform: rotate(-90deg);
}

.guardian-zone {
    scrollbar-width: thin;
    scrollbar-color: darkred #F5F5F5 !important;
    background-image: url("/images/field/guardian.png");
    height: 93px;
    width: 405px;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
}

.guardian-zone .guardian {
    flex-shrink: 0;
}

/* ---------- Trigger ---------- */

.trigger {
    width: 135px;
    height: 93px;
    /*margin-left: 21px;*/
}

.trigger .card {
    left: 21px;
    top: -21px;
    transform: rotate(90deg);
}

/*---------- Field zone ----------*/

.field-column {
    display: inline-block;
    position: relative;
    width: 135px;
    height: 270px;
}

.opponent .field-column .card-circle:only-child {
    margin-top: 135px;
}

.field-zone {
    scrollbar-width: thin;
    scrollbar-color: darkred #F5F5F5 !important;
    display: flex;
    width: 405px;
    position: relative;
    height: 270px;
    overflow-y: hidden;
    overflow-x: auto;
}

/*---------- Damage zone ----------*/

.damage-card {
    width: 135px;
    height: 93px;
}

.damage-card .card {
    left: 21px;
    top: -21px;
    transform: rotate(-90deg);
    /*overflow: hidden;*/
    box-shadow: 0 1px 4px 2px black;
}

.damage-zone {
    scrollbar-width: thin;
    scrollbar-color: darkred #F5F5F5 !important;
    position: relative;
    width: 135px;
    height: 270px;
    overflow-y: auto;
    overflow-x: hidden;
}

.damage-zone .damage-card {
    height: 45px;
}

.damage-zone .damage-card:last-child {
    height: 93px;
}

/* ---------- Player hand ---------- */

.hand {
    scrollbar-width: thin;
    scrollbar-color: darkred #F5F5F5 !important;
    display: flex;
    width: 540px;
    position: relative;
    height: 135px;
    overflow-y: hidden;
    overflow-x: auto;
}

/** --- Dialog cards --- **/

.dialog-cards {
    scrollbar-width: thin;
    scrollbar-color: darkred #F5F5F5 !important;
    margin: auto;
    display: flex;
    width: 279px;
    position: relative;
    height: 135px;
    overflow-y: hidden;
    overflow-x: auto;
}

.dialog-actions {
    width: 273px;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.dialog-actions .menu-button {
    margin-top: 2px;
}

.dialog-actions .menu-button:last-child {
    margin-bottom: 12px;
}

/* ---------- Avatar ---------- */

.profile {
    position: relative;
    width: 135px;
    height: 135px;
}

.profile .avatar {
    width: 135px;
    height: 135px;
    border-radius: 70px;
}

.profile .name {
    width: 135px;
    height: 15px;
    position: absolute;
    background-color: lightgray;
    bottom: 0;
    text-align: center;
    vertical-align: center;
    border-radius: 7px;
    left: 0;
    color: black;
}

.profile .surrender {
    background-image: url("images/surrender.svg");
    background-size: 15px;
    position: absolute;
    left: 2px;
    bottom: 17px;
    width: 15px;
    height: 15px;
    filter: invert(100%);
}

/* ---------- Layout ---------- */

.thin-line {
    width: 675px;
    height: 93px;
    display: flex;
}

.single-line {
    width: 675px;
    height: 135px;
    display: flex;
}

.double-line {
    width: 675px;
    height: 270px;
    display: flex;
}

.field {
    width: 675px;
    height: 903px;
}

/* ---------- Preview ---------- */

.sidebar {
    width: 273px;
    height: 903px;
}

.card-preview {
    /*position: absolute;*/
    position: relative;
    font-size: 12px;
    color: black;
    /*left: 0px;*/
    /*top: 252px;*/
    width: 273px;
    height: 399px;
}

.card-preview .image {
    width: 273px;
    height: 399px;
    border-radius: 10px;
}

.card-preview .description {
    padding: 3px;
    width: 252px;
    background-color: #EBECF0;
    min-height: 10px;
    border: 1px solid gray;
    position: absolute;
    display: inline-block;
    bottom: 70px;
    left: 7px;
    /*z-index: 1;*/
}

.card-preview .name {
    width: 200px;
    font-weight: bold;
    text-align: center;
    background-color: #EBECF0;
    position: absolute;
    bottom: 44px;
    left: 37px;
    z-index: 1;
}

.chat-log {
    scrollbar-width: thin;
    scrollbar-color: darkred #F5F5F5 !important;
    top: 651px;
    width: 273px;
    height: 504px;
    overflow: auto;
    font-size: 12px;
    color: white;
}

/* ---------- Display ---------- */

.display {
    margin: auto;
    background-color: black;
    /*border: 1px solid white;*/
    display: flex;
    width: 948px;
    height: 905px;
}

.display-inner-container {
    width: 100%;
}

.menu-screen {
    width: 273px;
    margin: 200px auto 0 auto;
}

.menu-screen-login {
    padding-top: 135px;
}

.menu-screen .profile {
    margin: auto;
}

.menu-button {
    width: 273px;
}

.menu-screen button {
    margin-top: 2px;
}

/** ----- User profile ---- */

.user-picture-container, .user-name-container {
    scrollbar-width: thin;
    scrollbar-color: darkred #F5F5F5 !important;
    width: 277px;
    height: 400px;
    overflow: auto;
}

/** --- deck builder --**/

.sidebar button {
    width: 273px;
    margin-top: 2px;
}

.builder-clan-select {
    width: 100%;
}

.builder-card-table {
    height: 904px;
}

.validation-ok {
    color: green;
}

.validation-error {
    color: red;
}

/** --- deck select --- **/

.deck-button-private {
    /*margin-top: 2px;*/
    width: 241px;
    /*height: 40px;*/
}

.deck-clan-logo {
    width: 24px;
    height: 24px;
    -webkit-filter: grayscale(100%) brightness(1000%);
    filter: grayscale(100%) brightness(1000%);
}

.deck-button-public {
    width: 273px;
    /*height: 40px;*/
}

.deck-button-action {
    float: right;
    width: 32px;
    height: 34px;
}

/** --- Card Preview text --- **/

.vanguard-icon {
    /*background-position: -38px 0px;*/
    /*width: 19px;*/
    height: 17px;
    width: 15px;
    vertical-align: middle;
    background: url('/images/ability/vanguard.png') no-repeat;
    display: inline-block;
}

.guardian-icon {
    /*background-position: 0px 0px;*/
    /*width: 19px;*/
    height: 17px;
    width: 15px;
    vertical-align: middle;
    background: url('/images/ability/guardian.png') no-repeat;
    display: inline-block;
}

.rearguard-icon {
    /*background-position: -19px 0px;*/
    /*width: 19px;*/
    height: 17px;
    width: 15px;
    vertical-align: middle;
    background: url('/images/ability/rearguard.png') no-repeat;
    display: inline-block;
}

.critical-icon {
    height: 17px;
    width: 13px;
    vertical-align: middle;
    background: url('/images/ability/critical.png') no-repeat;
    display: inline-block;
}

.power-icon {
    height: 17px;
    width: 14px;
    vertical-align: middle;
    background: url('/images/ability/power.png') no-repeat;
    display: inline-block;
}

.zone-open {
    height: 17px;
    width: 6px;
    vertical-align: middle;
    background: url('/images/ability/left_bracket.png') no-repeat;
    display: inline-block;
}

.zone-close {
    height: 17px;
    width: 6px;
    vertical-align: middle;
    background: url('/images/ability/right_bracket.png') no-repeat;
    display: inline-block;
}

.ability-act {
    height: 17px;
    width: 25px;
    vertical-align: middle;
    background: url('/images/ability/act.png') no-repeat;
    display: inline-block;
}

.ability-auto {
    height: 17px;
    width: 25px;
    vertical-align: middle;
    background: url('/images/ability/auto.png') no-repeat;
    display: inline-block;
}

.ability-cont {
    height: 17px;
    width: 25px;
    vertical-align: middle;
    background: url('/images/ability/cont.png') no-repeat;
    display: inline-block;
}

.one-turn-icon {
    height: 17px;
    width: 41px;
    vertical-align: middle;
    background: url('/images/ability/1turn.png') no-repeat;
    display: inline-block;
}

.cost-icon {
    height: 17px;
    width: 8px;
    vertical-align: middle;
    background: url('/images/ability/cost.png') no-repeat;
    display: inline-block;
}

.separator-icon {
    height: 17px;
    width: 7px;
    vertical-align: middle;
    background: url('/images/ability/separator.png') no-repeat;
    display: inline-block;
}

.rest-icon {
    height: 17px;
    width: 13px;
    vertical-align: middle;
    background: url('/images/ability/rest.png') no-repeat;
    display: inline-block;
}

.stand-icon {
    height: 17px;
    width: 13px;
    vertical-align: middle;
    background: url('/images/ability/stand.png') no-repeat;
    display: inline-block;
}

.soul-blast-1-icon {
    height: 17px;
    width: 48px;
    vertical-align: middle;
    background: url('/images/ability/s_blast_1.png') no-repeat;
    display: inline-block;
}

.soul-blast-2-icon {
    height: 17px;
    width: 48px;
    vertical-align: middle;
    background: url('/images/ability/s_blast_2.png') no-repeat;
    display: inline-block;
}
.soul-blast-3-icon {
    height: 17px;
    width: 48px;
    vertical-align: middle;
    background: url('/images/ability/s_blast_3.png') no-repeat;
    display: inline-block;
}
.soul-blast-4-icon {
    height: 17px;
    width: 48px;
    vertical-align: middle;
    background: url('/images/ability/s_blast_4.png') no-repeat;
    display: inline-block;
}

.soul-blast-5-icon {
    height: 17px;
    width: 48px;
    vertical-align: middle;
    background: url('/images/ability/s_blast_5.png') no-repeat;
    display: inline-block;
}

.counter-blast-1-icon {
    height: 17px;
    width: 40px;
    vertical-align: middle;
    background: url('/images/ability/c_blast_1.png') no-repeat;
    display: inline-block;
}

.counter-blast-2-icon {
    height: 17px;
    width: 40px;
    vertical-align: middle;
    background: url('/images/ability/c_blast_2.png') no-repeat;
    display: inline-block;
}

.counter-charge-1-icon {
    height: 17px;
    width: 42px;
    vertical-align: middle;
    background: url('/images/ability/c_charge_1.png') no-repeat;
    display: inline-block;
}

.counter-charge-2-icon {
    height: 17px;
    width: 42px;
    vertical-align: middle;
    background: url('/images/ability/c_charge_2.png') no-repeat;
    display: inline-block;
}

.soul-charge-1-icon {
    height: 17px;
    width: 44px;
    vertical-align: middle;
    background: url('/images/ability/s_charge_1.png') no-repeat;
    display: inline-block;
}

.soul-charge-2-icon {
    height: 17px;
    width: 44px;
    vertical-align: middle;
    background: url('/images/ability/s_charge_2.png') no-repeat;
    display: inline-block;
}

.card-link {
    text-decoration: underline;
}

.combat {
    position: absolute;
    left: 28px;
    top: 25px;
    color: white;
}

.exit {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 18px;
    width: 20px;
    height: 20px;
    -moz-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
}
